<template>
  <div>
    <div class="d-flex align-end justify-space-between">
      <div class="titulo-pagina">
        <div>
          {{ $t('modulos.certificados.grafico_tendencia.titulo') }}
        </div>
        <div>
          <v-chip
            class="mr-1"
            small
          >
            {{ $t('modulos.certificados.grafico_tendencia.instrumento') }}:
            {{ form.instrumento?.codigoTag }}
          </v-chip>
          <v-chip
            class="mr-1"
            small
          >
            {{ $t('modulos.certificados.grafico_tendencia.tipo_instrumento') }}:
            {{ form.instrumento?.tipoInstrumento.nome }}
          </v-chip>
        </div>
      </div>
    </div>
    <v-form class="row mt-4">
      <input-select
        v-model="form.tipoGrafico"
        class="col-12 col-md-2"
        :label="$t('modulos.certificados.grafico_tendencia.tipo_grafico')"
        :options="opcoes.grafico"
      />
      <input-select
        v-model="form.faixaInstrumento"
        class="col-12 col-md-3"
        :label="$t('modulos.certificados.grafico_tendencia.faixa_instrumento')"
        :options="faixasInstrumento"
      />
      <input-certificado-avaliacao
        v-model="form.certificado"
        class="col-12 col-md-4"
        :label="$t('modulos.certificados.grafico_tendencia.certificados')"
        :instrumento-id="instrumentoId"
        situacao="Assinado"
        grafico-tendencia
      />
      <input-select
        v-model="form.tabelaCalibracao"
        :options="opcoes.tabelasCalibracao"
        class="col-12 col-md-3"
        :label="$t('modulos.certificados.grafico_tendencia.tabela_calibracao')"
      />
      <grafico
        :key="indiceGrafico"
        class="col-12"
        :certificados="grafico?.certificados"
        :tipo-grafico="form.tipoGrafico"
      />
    </v-form>
    <v-divider class="my-5" />
    <v-row class="ma-0 d-flex justify-end">
      <botao-padrao
        class="mr-2"
        outlined
        color="secondary"
        @click="cancelar"
      >
        <v-icon>$mdiArrowLeft</v-icon>
        {{ $t('geral.botoes.voltar') }}
      </botao-padrao>
    </v-row>
  </div>
</template>
<script>
import { InputCertificadoAvaliacao } from '@/components/inputs';
import {
  InstrumentoService,
  CalibracaoService,
  CertificadoService,
} from '@common/services';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import helpers from '@/common/utils/helpers';
import _ from 'lodash';
import Grafico from './grafico';
export default {
  components: {
    InputCertificadoAvaliacao,
    Grafico,
  },
  props: ['instrumentoId', 'certificadoId'],
  data: function () {
    return {
      form: {
        instrumento: null,
        tipoGrafico: 'ErroIncerteza',
        faixaInstrumento: null,
        certificado: [],
        tabelaCalibracao: null,
      },
      opcoes: {
        tabelasCalibracao: [],
        grafico: [
          {
            value: 'Erro',
            text: this.$t(
              'modulos.certificados.definir_derivas.opcoes.graficos.erro'
            ),
          },
          {
            value: 'Incerteza',
            text: this.$t(
              'modulos.certificados.definir_derivas.opcoes.graficos.incerteza'
            ),
          },
          {
            value: 'ErroIncerteza',
            text: this.$t(
              'modulos.certificados.definir_derivas.opcoes.graficos.erro_incerteza'
            ),
          },
        ],
      },
      grafico: null,
      indiceGrafico: 1,
    };
  },
  beforeMount() {
    this.buscarInstrumento();
    if (this.instrumentoId && !this.certificadoId)
      this.buscarTodosCertificadosInstrumento(this.instrumentoId);
  },
  mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.certificados.grafico_tendencia.titulo')
    );
    if (this.certificadoId) this.buscarCertificado(this.certificadoId);
  },
  methods: {
    buscarTodosCertificadosInstrumento: function (id) {
      const params = {
        InstrumentoId: id,
        flagTrazerTerceiro: true,
        Situacao: 'Assinado',
      };
      CertificadoService.listar(params).then((res) => {
        this.form.certificado = res.data.items.map((el) => {
          return {
            value: el.id,
            text: el.codigo,
          };
        });
      });
    },

    cancelar: function () {
      this.$router.push({ name: this.$route.query?.retorno || 'certificado' });
    },
    buscarCertificado: function (id) {
      CalibracaoService.buscarPorId(id).then((res) => {
        this.form.certificado = [
          { value: id, text: res.data.codigoCertificado },
        ];
      });
    },
    buscarInstrumento: function () {
      InstrumentoService.buscar(this.instrumentoId).then((res) => {
        this.form.instrumento = res.data;
        if (this.form.instrumento.instrumentoFaixa.length > 0)
          this.form.faixaInstrumento =
            this.form.instrumento.instrumentoFaixa[0].id;
      });
    },
    buscarTabelasCalibracao: async function () {
      this.opcoes.tabelasCalibracao = [];
      if (this.form.certificado.length != 1) return;
      await CalibracaoService.buscarTabelas(
        this.form.certificado[0].value
      ).then((res) => {
        this.opcoes.tabelasCalibracao = res.data.map((el) => {
          return {
            value: el.tabela.id,
            text: `${el.procedimentoEletronico.nome} - ${el.tabela.nome}${el.unidadeMedida
                ? ` - ${helpers.formatarNumeroComPrecisao(
                  el.amplitudeMinima
                )} a ${helpers.formatarNumeroComPrecisao(
                  el.amplitudeMaxima
                )} ${el.unidadeMedida.nome}`
                : ''
              }`,
          };
        });
      });
    },
    buscarGrafico: _.debounce(async function () {
      this.grafico = {};
      if (this.form.certificado.length == 0) return;
      this.$store.dispatch('Layout/iniciarCarregamento', 'Carregando Gráfico');
      await InstrumentoService.buscarGraficoTendencia(
        this.instrumentoId,
        this.form.faixaInstrumento,
        this.form.certificado.map((el) => el.value),
        this.form.tabelaCalibracao
      )
        .then((res) => {
          this.grafico = res.data;
        })
        .finally(() => {
          setTimeout(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          }, 1000);
        });
    }, 400),
  },
  computed: {
    faixasInstrumento: function () {
      if (!this.form.instrumento) return [];
      return new DropdownModel(
        this.form.instrumento.instrumentoFaixa,
        'descricao'
      );
    },
  },
  watch: {
    'form.tipoGrafico': function () {
      this.indiceGrafico++;
    },
    'form.faixaInstrumento': async function () {
      await this.buscarGrafico();
      this.indiceGrafico++;
    },
    'form.certificado': async function () {
      this.form.tabelaCalibracao = null;
      await this.buscarTabelasCalibracao();
      await this.buscarGrafico();
      this.indiceGrafico++;
    },
    'form.tabelaCalibracao': async function () {
      await this.buscarGrafico();
      this.indiceGrafico++;
    },
  },
};
</script>
