<template>
  <div>
    <template>
      <div
        v-show="mostrarGrafico"
        ref="chart"
        style="width: 100%; height: 300px;"
      />
    </template>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import helpers from '@/common/utils/helpers';
import _ from 'lodash';

export default {
  props: {
    certificados: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tipoGrafico: {
      type: String,
      default: 'Erro',
    },
  },
  data: function () {
    return {
      chart: null,
      mostrarGrafico: true,
    };
  },
  watch: {
    certificados: {
      async handler() {
        await this.alterarOptions();
      },
      immediate: true,
    },
    tipoGrafico: async function () {
      await this.alterarOptions();
    },
  },
  methods: {
    delay: function (milliseconds) {
      return new Promise((resolve) => {
        setTimeout(resolve, milliseconds);
      });
    },
    alterarOptions: async function () {
      if(!this.certificados?.length) return;
      let dataset = this.montarDataset();
      let min = undefined;
      this.certificados.forEach(certificado => {
        if (!this.tipoGrafico == 'ErroIncerteza') return;
        const incertezas = this.retornarPontosIncerteza(certificado)
        min = _.min(incertezas, e => e[2])[2]
      });
      
      let option = {
        dataset: dataset,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
          },
          formatter: (params) => {
            const ponto = params[0].axisValue;
            const colunas = this.certificados?.length;

            let tip = `Ponto: ${
              params[0].axisValue
            }<div class='tooltip-grafico-tendencia' style='grid-template-columns: ${'1fr '.repeat(
              colunas > 2 ? 3 : colunas
            )};'> `;
            this.certificados.forEach((c) => {
              const plotagem = params.find((el) => {
                return el.seriesName == c.id;
              });

              if (!plotagem) return;
              
              tip += `<div><hr /><strong>${plotagem.marker} ${c.codigo}</strong><br/>`;
              const dadosPonto = c.linhas.find(
                (el) => el.pontoCalibracao == ponto
              );

              tip += `<small>Erro: ${dadosPonto.erro || ''}</small><br/>`;
              tip += `<small>Incerteza: ${
                dadosPonto.incerteza || ''
              }</small><br/>`;
              tip += `<small>Critério de aceitação: ${
                dadosPonto.criterioValores?.length > 0
                  ? dadosPonto.criterioValores[0]
                  : ''
              }</small><br/>`;

              tip += `<small>Formula de validação: ${
                dadosPonto.formulaValidacao
                  ? helpers.FormulaValidacaoEnum.find(
                      (el) => el.value == dadosPonto.formulaValidacao
                    )?.text
                  : ''
              }</small><br/>`;
              tip += `<small>Situação da avaliação: ${
                dadosPonto.situacaoAvaliacao
                  ? helpers.SituacaoAvaliacaoEnum.find(
                      (el) => el.value == dadosPonto.situacaoAvaliacao
                    )?.text
                  : 'Não Avaliado'
              }</small><br/> </div>`;
            });

            tip += '</div>';
            return tip;
          },
        },
        xAxis: {
          splitLine: {
            lineStyle: {
              type: 'solid',
            },
          },
        },
        yAxis: {
          splitLine: {
            lineStyle: {
              type: 'solid',
            },
          },
          min: min
        },
        series: this.montarSeries(),
      };
      await this.delay(1000);
      this.chart = echarts.init(this.$refs.chart);
      this.chart.setOption(option);
    },
    retornarErrorSerie: function (certificado) {
      return {
        type: 'custom',
        name: `${certificado.id}-incerteza`,
        itemStyle: {
          borderWidth: 1.5,
        },
        renderItem: function (params, api) {
          var xValue = api.value(0);
          var highPoint = api.coord([xValue, api.value(1)]);
          var lowPoint = api.coord([xValue, api.value(2)]);
          var halfWidth = 5;

          var style = api.style({
            stroke: api.visual('color'),
            fill: undefined,
          });
          return {
            type: 'group',
            children: [
              {
                type: 'line',
                transition: ['shape'],
                shape: {
                  x1: highPoint[0] - halfWidth,
                  y1: highPoint[1],
                  x2: highPoint[0] + halfWidth,
                  y2: highPoint[1],
                },
                style: style,
              },
              {
                type: 'line',
                transition: ['shape'],
                shape: {
                  x1: highPoint[0],
                  y1: highPoint[1],
                  x2: lowPoint[0],
                  y2: lowPoint[1],
                },
                style: style,
              },
              {
                type: 'line',
                transition: ['shape'],
                shape: {
                  x1: lowPoint[0] - halfWidth,
                  y1: lowPoint[1],
                  x2: lowPoint[0] + halfWidth,
                  y2: lowPoint[1],
                },
                style: style,
              },
            ],
          };
        },
        data: this.retornarPontosIncerteza(certificado),
        z: 100,
      };
    },
    montarDataset: function () {
      let dataset = [];
      this.certificados.forEach((certificado) => {
        dataset.push(
          this.retornarPontosDistinct(
            certificado,
            this.tipoGrafico == 'Incerteza' ? 'incerteza' : 'erro'
          )
        );
      });
      return dataset;
    },
    montarSeries: function () {
      let series = [];

      this.certificados.forEach((certificado, indice) => {
        series.push({
          name: `${certificado.id}`,
          type: 'line',
          datasetIndex: indice,
        });

        if (this.tipoGrafico == 'ErroIncerteza')
          series.push(this.retornarErrorSerie(certificado));
      });
      return series;
    },
    retornarPontosDistinct: function (certificado, elemento = 'ponto') {
      let data = [];

      if (certificado)
        data = certificado.linhas
          .map((el) => [el.pontoCalibracao, el[elemento]])
          .filter((value, index, array) => {
            return (
              array.indexOf(
                array.find((el) => el[0] == value[0] && el[1] == value[1])
              ) === index
            );
          });
      return { source: data };
    },

    retornarPontosIncerteza: function (certificado) {
      let data = [];
      if (certificado)
        data = certificado.linhas
          .map((el) => [
            el.pontoCalibracao,
            el.erro + Math.abs(el.incerteza),
            el.erro - Math.abs(el.incerteza),
          ])
          .filter((value, index, array) => {
            return (
              array.indexOf(
                array.find(
                  (el) =>
                    el[0] == value[0] && el[1] == value[1] && el[2] == value[2]
                )
              ) === index
            );
          });
      return data;
    },
  },
};
</script>
<style>
.tooltip-grafico-tendencia {
  display: grid;

  gap: 8px;
}
</style>
